import React from "react"

import * as styles from "./ContentWithSidebar.module.scss"

const ContentWithSidebar = ({children}) => (

    <div className={styles.ContentWithSidebar}>
        {children}
    </div>

)

export default ContentWithSidebar