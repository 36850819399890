import React from "react"
import { ResponsiveContext } from "grommet"

// Styles
import * as styles from "./Subnavigation.module.scss"

// Component
import SubNavigationMenu from "./SubNavigationMenu"

const Subnavigation = (props) => {

    // console.log(props)
    const mobileTitle = () => {
        if (props.sectionTitle) {
            return props.sectionTitle + ": In this section"
        } else {
            return "In this section"
        }
    }

    const hasVenueAncestor = props.page.ancestors?.nodes && props.page.ancestors?.nodes.slice(-1)[0].pageTemplate === "home-page"
    const ancestorsCount = props.page.ancestors?.nodes && props.page.ancestors?.nodes.length

    let rootPage
    if (hasVenueAncestor) {
        switch (ancestorsCount) {
            case 0:
                rootPage = props.page
                break
            case 1:
                rootPage = props.page
                break
            case 2:
                rootPage = props.page.ancestors.nodes[1]
                break
            default:
                rootPage = props.page.ancestors.nodes[2]
        }
    } else {
        if(props.page.parent !=null){
            rootPage =  props.page.ancestors.nodes[0].id
         
        }
    }

    let parentPage = props.page
    if (props.page.parent && props.page.ancestors[0] === rootPage) {
        parentPage = props.page
    } else if (props.page.parent) {
        parentPage = props.page.parent
    }

    const shouldRenderSubnavigation = props.page.ancestors?.nodes[0].children.nodes.length > 0
    // console.log(props.page.ancestors.nodes[0].children)

    // console.log(shouldRenderSubnavigation)
    if (shouldRenderSubnavigation || props.page) {
        return (
            <div className={styles.Subnavigation}>
                {(props.page.children || props.page.ancestors.nodes[0]) && (
                    <ResponsiveContext.Consumer>
                        {(responsive) =>
                            responsive === "smallMobile" || responsive === "mobile" ? (
                                <>
                                    <h3>{mobileTitle()}</h3>
                                </>
                            ) : (
                                <>
                                {props.page.ancestors?.nodes && (
                                   <h3 className={styles.Subnavigation_back}><a href={props.page.ancestors?.nodes[0].link}> Back to {props.page.ancestors?.nodes[0].title}</a> </h3>
                                )}
                                   <h3>In this section</h3>
                                </>
                            )
                        }
                    </ResponsiveContext.Consumer>
                )}

                <SubNavigationMenu rootPage={props.page.ancestors?.nodes[0] || props.page} currentPage={props.page} parentPage={props.page.ancestors?.nodes[0] || props.page} />
            </div>
        )
    } else {
        return <></>
    }
    // return <div>Hello subnav</div>
}

export default Subnavigation
