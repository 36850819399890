import React from "react"

import * as styles from "./ArticleHeader.module.scss"

const ArticleHeader = (props) => (
    <div className={styles.ArticleHeader}>
        <div>
            <h1 dangerouslySetInnerHTML={{ __html: props.title }}></h1>
        </div>
        <div className={styles.introduction} dangerouslySetInnerHTML={{ __html: props.introduction }}></div>
    </div>
)

export default ArticleHeader
