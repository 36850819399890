import React from "react"

// Styles
import * as styles from "./HeroAreaSmall.module.scss"

// Components
import PlaceholderImage from "./PlaceHolderImage"
import ArchiveLabel from "../Common/ArchiveLabel"
import ParallaxItem from "./ParallaxItem"
import Image from "./Image"
import { imageSizes } from "../../helpers/imgix"

const HeroAreaSmall = (props) => {
    // console.log("HeroAreaSmall props", props)
    return (
        <div className={styles.HeroAreaSmall}>
            <div className={styles.image}>
                {props.image && (
                    <ParallaxItem maxAmount="500">
                        <Image url={props.image.sourceUrl} alt={props.image.title} sizes={imageSizes.hero} />
                    </ParallaxItem>
                )}
            </div>
            {props.archive && <ArchiveLabel />}
            <div className={styles.title}>
                {props.backlink && (
                    <a href={props.backlink}>
                        <span>See all</span>
                        <h1 dangerouslySetInnerHTML={{ __html: props.title }}></h1>
                    </a>
                )}
                {!props.backlink && <h1 dangerouslySetInnerHTML={{ __html: props.title }}></h1>}
            </div>
        </div>
    )
}

export default HeroAreaSmall
