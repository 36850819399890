import React, { useState, useEffect } from "react"
import { Query } from "@apollo/react-components"
import queryString from "query-string"
import PreviewBanner from "../components/Shared/PreviewBanner"

const withPreview = (args = { preview: false }) => (Component) => {
    const preview = (props) => {
        const search = queryString.parse(props.location.search)

        const { id, revision_id, token } = search

        if (!token || !id || !revision_id) {
            return <Component preview={false} {...props} />
        }

        return (
            <Query query={args.preview} variables={{ id, revision_id, token }}>
                {({ data }) => {
                    const [isClient, setIsClient] = useState(false)

                    useEffect(() => {
                        setIsClient(true)
                    }, [])

                    return (
                        <React.Fragment key={isClient}>
                            <PreviewBanner />
                            <Component preview={data} {...props} />
                        </React.Fragment>
                    )
                }}
            </Query>
        )
    }

    return preview
}

export const getContent = (props, name) => {
    // console.log("preview props ", props)

    const hasPreview = props.preview && props.preview[name].revisions.edges.length > 0
    //const contentObject = props.data.wpgraphql[name]
    const contentObject = props.data ? props.data[name] : props[name]

    const content = hasPreview ? { ...contentObject, ...props.preview[name].revisions.edges[0].node } : contentObject
    return content
}

export default withPreview
