import React from "react"

// Styles
import * as styles from "./PreviewBanner.module.scss"

const PreviewBanner = () => {
    return (
        <div className={styles.PreviewBanner}>
            <div className={styles.textContent}>
                <a href={`${location.pathname}`}>Preview mode &times;</a>
            </div>
        </div>
    )
}

export default PreviewBanner
