import React, { useMemo } from "react"
import * as styles from "./Subnavigation.module.scss"
import { dynamicSort } from "../../helpers/sort"

const SubNavigationMenu = (props) => {
    // console.log(props)
    // console.log(props)
    const activeClass = (page) => {
        if (page.id === props.currentPage.id) {
            return styles.isCurrent
        }

        return ""
    }

    const ancestorClass = (page) => {
        if (page.id === props.currentPage.ancestors?.nodes[0].id) {
            return styles.isAncestor
        }
        return ""
    }
    
    // const reOrderedRootChildren = props.currentPage.ancestors.nodes[0].children.nodes;
    const reOrderedRootChildren = useMemo(() => {
        // console.log(props)
        const arrayToSort = props.rootPage.children.nodes 
        // console.log('rootPage', props.rootPage)

        // if (!arrayToSort.length) {
        //     return []
        // }

        return arrayToSort.sort(dynamicSort("menuOrder"))
    }, [props.rootPage.children.nodes])


    return (
        <ul>
            {reOrderedRootChildren.map((page) => {

                // console.log("page:", page, "props",props )
               
                return (
                    <li key={page.id} className={`${activeClass(page)} ${ancestorClass(page)}`}>
                        <a href={page.link} dangerouslySetInnerHTML={{ __html: page.title }}></a>
                        
                       
                        
                        {page.children?.nodes.length > 0  && (page.id === props.currentPage.id || page.id === props.currentPage.ancestors?.nodes[0].id?.node?.id) &&(
                            <>
                          
                               <SubNavigationMenu rootPage={page} currentPage={props.rootPage} parentPage={props.currentPage.ancestors?.nodes[0]} subSubMenu={true} /> 
                               
                                

                             {/* <SubNavigationMenu rootPage={props.currentPage.ancestors.nodes[0]} currentPage={page} parentPage={page} /> */}
                            
                            </>


                        )}

                        {/* {page.children.nodes.length > 0 && page.id === props.currentPage.ancestors.nodes[0].id?.node?.id &&(
                            <>
                                <SubNavigationMenu rootPage={props.currentPage.ancestors.nodes[0]} currentPage={page} parentPage={props.currentPage} /> 
                            </>
                        )} */}
                       
                    </li>
                )
            })}
        </ul>
    )
}

export default SubNavigationMenu
