import React from "react"

import * as styles from "./ArchiveLabel.module.scss"

const ArchiveLabel = () => {
    return (
        <div className={styles.archiveLabel}>
            <div className={styles.text}>Archive</div>
        </div>
    )
}

export default ArchiveLabel
